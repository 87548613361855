const {
  HISTORY_FIELDS,
  INVOICE_STATUS,
  PAYMENT_TYPE,
  PAYMENT_STATUS,
  EXTRA_ORDER_TYPE,
  REVIEW_STATUS,
  PRODUCTION_STATUS,
  REFUND_TYPES,
  REFUND_STATUS,
  RETURN_STATUS,
  MONTHLY_ORDER_STATUS,
  DELIVERY_TYPE,
} = require('../dictionary');
const {displayStatus} = require('../Utils/LogisticUtil');

const customMapping = {
  Order: {
    payment_type: (val) => PAYMENT_TYPE[val],
    payment_status: (val) => PAYMENT_STATUS[val],
    voided: (val) => (val ? '是' : '否'),
  },
  OrderItemReview: {
    status: (val) => REVIEW_STATUS[val],
    production_status: (val) => PRODUCTION_STATUS[val],
  },
  Logistics: {
    logistics_status: (val, record) => displayStatus(record),
    logistics_type: (val) => DELIVERY_TYPE[val],
  },
  Invoice: {
    invoice_status: (val) => INVOICE_STATUS[val],
  },
  Refund: {
    refund_type: (val) => REFUND_TYPES[val],
    status: (val) => REFUND_STATUS[val],
  },
  ReturnApplication: {
    status: (val) => RETURN_STATUS[val],
  },
  'Extra Order': {
    extra_type: (val) => EXTRA_ORDER_TYPE[val],
  },
  MonthlyOrder: {
    payment_status: (val) => MONTHLY_ORDER_STATUS[val],
  },
};

function parse(model, value) {
  let result = [];
  if (!HISTORY_FIELDS[model]) {
    return;
  }

  result = Object.keys(value)
    .filter((key) => !!HISTORY_FIELDS[model][key])
    .map(
      (key) =>
        `${HISTORY_FIELDS[model][key]}:${
          typeof customMapping[model]?.[key] === 'function'
            ? customMapping[model]?.[key](value[key], value)
            : value[key]
        }`,
    );
  // through custom mapping or original value

  return result.join('； ');
}

module.exports = {
  parse,
};
