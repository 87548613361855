const pricingTypes = [
  'default',
  'single',
  'square',
  'starting',
  'digital',
  'size',
  'common',
  'custom',
];

const emptySpec = {
  version: '',
  ...pricingTypes.reduce((sum, p) => {
    sum[p] = [];
    return sum;
  }, {}),
};

module.exports = {
  pricingTypes,
  emptySpec,
};
