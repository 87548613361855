import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import Button from '../../Widgets/Button';
import {Input, message, Collapse, Modal} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import Block from '../../Widgets/Block';
import Anchor from '../../Widgets/Anchor';
import Center from '../../Widgets/Center';
import Empty from '../../Widgets/Empty';
import Row from '../../Widgets/Row';
import Hint from '../../Widgets/Hint';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {getInvoiceCategory} from '../../Utils/InvoiceUtil';
import {TAX_TYPE, INVOICE_STATUS} from '../../dictionary';
const appConfig = require('../../data.json');

function InvoiceDialog({visible, onClose, buyer, monthlyOrder, onUpdate}) {
  const [actions] = useOutlet('actions');
  const [data, setData] = useState({
    item_name: '月結訂單商品1批',
  });

  const submit = useCallback(async (data) => {
    actions.setLoading(true);

    try {
      await actions.createMonthlyInvoice({
        monthly_order: monthlyOrder.id,
        item_name: data.item_name,
      });
      onUpdate();
      onClose();
      message.success('開立成功');
    } catch (err) {
      errorHandler(err);
    }
    actions.setLoading(false);
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="開立月結對帳單發票"
      footer={
        <Row>
          <div style={{flex: 1}} />
          <Button type="primary" onClick={() => submit(data)}>
            開立
          </Button>
        </Row>
      }>
      <Block>
        <div style={{padding: 0}}>
          <div className="row">
            <Hint type="info">
              月結對帳單發票是三聯式，資訊由會員之抬頭統編取得
            </Hint>
          </div>
          <div className="row">
            <h4>發票種類</h4>
            <div>三聯式發票</div>
          </div>
          <div className="row">
            <h4>公司抬頭</h4>
            <div>{buyer?.ent_name}</div>
          </div>
          <div className="row">
            <h4>公司統編</h4>
            <div>{buyer?.gui_number}</div>
          </div>
          <div className="row">
            <h4>品項</h4>
            <Input
              value={data.item_name}
              onChange={(e) =>
                setData((prev) => ({...prev, item_name: e.target.value}))
              }
            />
          </div>
          <div className="row">
            <h4>金額</h4>
            <div>{monthlyOrder.amount}</div>
          </div>
        </div>
      </Block>
    </Modal>
  );
}

function Item({record, onItemClick}) {
  return (
    <React.Fragment>
      <div className="row">
        <h4>發票號碼</h4>
        <Anchor type="button" onClick={onItemClick}>
          {record.invoice_number
            ? `${record.invoice_number} (#${record.id})`
            : `--`}
        </Anchor>
      </div>
      <div className="row">
        <h4>發票類型</h4>
        <div>{getInvoiceCategory(record)}</div>
      </div>
      <div className="row">
        <h4>買受人</h4>
        <div>{record.buyer_name || '-'}</div>
      </div>
      <div className="row">
        <h4>税別</h4>
        <div>{TAX_TYPE[record.tax_type] || '-'}</div>
      </div>
      <div className="row">
        <h4>總額</h4>
        <div>{record.amount}</div>
      </div>
      <div className="row">
        <h4>狀態</h4>
        <div>{INVOICE_STATUS[record.invoice_status] || '-'}</div>
      </div>
      <div className="row">
        <h4>開立日期</h4>
        <div>
          {record.created.slice(0, 10)} {record.created.slice(11, 16)}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function InvoiceBlock({monthlyOrder, records, onUpdate, buyer}) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Block>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>
          <h2>發票資訊</h2>
          <div className="subtitle">結算時自動產生</div>
        </div>
        <div style={{flex: 1}} />
        <Button
          type="primary"
          size="small"
          style={{marginRight: 10}}
          onClick={() => {
            setOpenDialog(true);
          }}>
          開立發票
        </Button>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={onUpdate}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </div>
      <div className="divider" />
      {records.length > 0 ? (
        <div>
          <Collapse defaultActiveKey={['0']}>
            {records.map((record, idx) => (
              <Collapse.Panel
                key={idx}
                header={
                  <div style={{display: 'inline-flex', fontSize: 16}}>
                    <div style={{marginRight: 10}}>發票號碼</div>
                    <Anchor to={`/invoice/?id=${record.id}`}>
                      {record.invoice_number
                        ? `${record.invoice_number} (#${record.id})`
                        : `--`}
                    </Anchor>
                  </div>
                }>
                <Item
                  record={record}
                  onItemClick={() => {
                    navigate(`/invoice/?id=${record.id}`);
                  }}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      ) : (
        <Center>
          <Empty />
        </Center>
      )}

      {openDialog && (
        <InvoiceDialog
          visible={openDialog}
          onClose={() => setOpenDialog(false)}
          monthlyOrder={monthlyOrder}
          buyer={buyer}
          onUpdate={onUpdate}
        />
      )}
    </Block>
  );
}
