export const DELIVERY_STATUS = {
  default: '未處理',
  packaging: '打包中',
  tallying: '待出貨',
  pending: '出貨中',
  transit: '＊已出貨',
  pickup: '運送中',
  delivered: '已送達',
  undelivered: '未送達',
  notfound: '無追蹤中包裹',
  exception: '例外事故',
  expired: '過期',
  cancelled: '已作廢',
};

export const SELF_DELIVERY_STATUS = {
  default: '未處理',
  packaging: '打包中',
  tallying: '等待出貨',
  transit: '＊可自取',

  pending: '出貨中',
  pickup: '已出貨',
  notfound: '無追蹤中包裹',
  delivered: '已自取',
  undelivered: '未送達',
  exception: '例外事故',
  expired: '過期',
  cancelled: '已取消',
};

export const CAN_DISPATCH_LOGISTICS = ['sf', 'hct', 'payuni', 'xdelivery'];
const EDITABLE_STATUSES = ['default', 'packaging'];

export function getStatusSet({type}) {
  if (type === 'self_pick') {
    return {
      default: SELF_DELIVERY_STATUS.default,
      packaging: SELF_DELIVERY_STATUS.packaging,
      tallying: SELF_DELIVERY_STATUS.tallying,
      transit: SELF_DELIVERY_STATUS.transit,
      delivered: SELF_DELIVERY_STATUS.delivered,
      cancelled: SELF_DELIVERY_STATUS.cancelled,
    };
  }
  if (type === 'ezship') {
    return {
      ...DELIVERY_STATUS,
      tallying: '等待出貨',
    };
  }
  return DELIVERY_STATUS;
}

export function displayStatus(instance) {
  let label = '';
  let stateSet = getStatusSet({type: instance.logistics_type});
  try {
    if (instance) {
      label = stateSet[instance.logistics_status];
    }
  } catch (error) {}

  return label || instance.logistics_status;
}

export function getEditableFields(logistic = {}) {
  const isDispatchAble = CAN_DISPATCH_LOGISTICS.includes(
    logistic?.logistics_type,
  );
  const isEditableStatus = EDITABLE_STATUSES.includes(
    logistic?.logistics_status,
  );
  // 這邊的 status 指的是 logistics_status ( 物流狀態 )
  if (isEditableStatus) {
    return {allFields: true, status: true};
  }

  if (isDispatchAble) {
    return {allFields: false, status: false};
  }

  return {allFields: false, status: true};
}
