import React, {useEffect, useMemo, useState} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import {Modal, Spin, Result} from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {Close} from '@styled-icons/material/Close';
import Button from '../Widgets/Button';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 10,
  },
});

export default function PdfPreviewDialog(props) {
  const {children, onClose, visible, loading} = props;
  const [showDownload, setShowDownload] = useState(false);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setShowDownload(true);
        // fix: font register fail, when render preview and link both
        // workaroud: https://github.com/diegomura/react-pdf/issues/777#issuecomment-763303776
      }, 1000);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="預覽PDF"
      footer={
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} />
          {showDownload ? props.PDFDownloadComp : null}
          <Button style={{marginLeft: 10}} onClick={onClose}>
            關閉
          </Button>
        </div>
      }
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      onCancel={onClose}
      width="95%">
      {loading && !children ? (
        <Result
          icon={
            <Spin indicator={<LoadingOutlined style={{fontSize: 40}} spin />} />
          }
          title="正在取得資料..."
        />
      ) : (
        <PDFViewer
          style={{width: '100%', height: '100vh'}}
          key={Math.random()}
          showToolbar={false}>
          {children}
        </PDFViewer>
      )}
    </Modal>
  );
}

function EmptyDocument() {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text>無法顯示</Text>
        </View>
      </Page>
    </Document>
  );
}
