import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import {pricingTypes} from '../pricingTypes';

function findSpecByProductName({productSpec, name}) {
  for (let t of pricingTypes) {
    if (productSpec[t]) {
      // check for new pricing type
      for (let p of productSpec[t]) {
        if (p.name === name) {
          return p;
        }
      }
    }
  }

  throw new Error(`No such name in product spec: ${name}`);
}

function getOptionByName({param, name, material}) {
  const selectedOption = material
    ? param.options.find((o) => o.material === material)
    : param.options.find((o) => o.name === name);

  if (!selectedOption) {
    console.warn(`no option ${name} in spec`);

    return {
      name,
      label: name,
    };
  }

  return selectedOption;
}

function parseProductConfig({itemInCart, productSpec, order}) {
  const spec = findSpecByProductName({
    productSpec,
    name: order.order_type === 'custom' ? '自定義商品' : itemInCart.name,
  });
  console.log('spec', spec);

  const itemConfig = itemInCart.config;
  console.log('itemConfig', itemConfig);
  const result = [];

  Object.keys(itemConfig).forEach((k) => {
    const param = spec[k];

    if (!param) {
      throw new Error(`product ${itemInCart.name} has no select ${k} in spec`);
    }

    if (param.type === 'single') {
      const option = getOptionByName({param, name: itemConfig[k]});
      result.push(`${param.label || k}: ${option.label || option.name}`);
    } else if (param.type === 'multiple') {
      const all = itemConfig[k].reduce((acc, name) => {
        const option = getOptionByName({param, name});
        return `${acc} ${option.label || name}`;
      }, '');
      result.push(`${param.label || k}: ${all}`);
    } else if (param.type === 'number') {
      result.push(`${param.label || k}: ${itemConfig[k]}`);
    } else if (param.type === 'group-number') {
      const option = getOptionByName({param, name: itemConfig[k].name});
      result.push(
        `${param.label || k}: ${option.label || option.name} ${
          itemConfig[k].quantity
        }`,
      );
    } else if (param.type === 'master') {
      result.push(`材質: ${itemConfig[k].material}`);
      result.push(`數量: ${itemConfig[k].quantity}`);
      result.push(`尺寸: ${itemConfig[k].size}`);
    }
  });

  return result;
}

const getQuantity = (orderItem) => {
  try {
    const quantityConfig =
      orderItem.config.material_select || orderItem.config.quantity_select;

    if (typeof quantityConfig === 'number') {
      return quantityConfig;
    } else if (typeof quantityConfig === 'object') {
      return quantityConfig.quantity;
    } else {
      return '---';
    }
  } catch (ex) {
    // bypass
  }
  return '---';
};

const PdfCartItemTable = ({cart, productSpec, order}) => {
  return (
    <>
      <View style={styles.firstRow}>
        <Text style={styles.firstRowCell}>品項</Text>
        <Text style={styles.firstRowCellBig}>商品名稱</Text>
        <Text style={styles.firstRowCellBig}>規格</Text>
        <Text style={styles.firstRowCell}>數量</Text>
        <Text style={styles.firstRowCellBig}>小計</Text>
      </View>

      {cart.items.map((item, idx) => {
        const parsedConfigs = parseProductConfig({
          itemInCart: item,
          productSpec,
        });

        return (
          <View style={styles.row}>
            <Text style={styles.cell}>{`${idx + 1}`}</Text>
            <Text style={styles.cellBig}>{order.custom_note || item.name}</Text>
            <View style={styles.cellBig}>
              {parsedConfigs.map((config, idx) => (
                <View key={idx}>
                  <Text style={{fontSize: 10}}>{config}</Text>
                </View>
              ))}
              {order.order_type === 'custom' ? (
                <View>
                  <Text style={{fontSize: 10}}>
                    自定規格：{item.custom_note}
                  </Text>
                </View>
              ) : null}
            </View>
            <Text style={styles.cell}>{getQuantity(item)}</Text>
            <Text style={styles.cellBig}>
              NT$
              {item.amount === item.price
                ? (item.amount * 1.05).toFixed(0)
                : item.price}
            </Text>
          </View>
        );
      })}
    </>
  );
};

const styles = StyleSheet.create({
  firstRow: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2f2f2',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  firstRowCell: {
    textAlign: 'center',
    fontSize: 10,
    flex: 1,
    padding: 2,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  firstRowCellBig: {
    textAlign: 'center',
    fontSize: 10,
    flex: 2,
    padding: 2,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cell: {
    fontSize: 10,
    flex: 1,
    padding: 2,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellBig: {
    flexDirection: 'column',
    fontSize: 10,
    flex: 2,
    padding: 2,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
});

export default PdfCartItemTable;
export {getQuantity, parseProductConfig};
