import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {errorHandler} from '../../errors';
import {useOutlet} from 'reconnect.js';
import BackButton from '../../Components/BackButton';
const appConfig = require('../../data.json');
const qs = require('query-string');
import OrderTable, {TableTypes} from '../../Components/OrderTable';
import {navigate} from 'gatsby';
import {dateParser} from '../../Utils/DateUtil';
import {
  MONTHLY_ORDER_STATUS,
  MONTHLY_ORDER_STATUS_COLOR,
} from '../../dictionary';
import Dialog, {TYPE as DIALOG_TYPE} from '../../Dialogs/MonthlyDialog';
import PdfPreviewDialog from '../../Dialogs/PdfPreviewDialog';
import {
  MonthlyFormPdfDownload,
  MonthlyDocument,
} from '../../Exports/MonthlyFormPdf';
import Button from '../../Widgets/Button';
import Block from '../../Widgets/Block';
import Row from '../../Widgets/Row';
import Tag from '../../Widgets/Tag';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import InvoiceBlock from './InvoiceBlock';
import MemberEnterpriseBlock from '../MemberDetailPage/MemberEnterpriseBlock';
import MonthlyHistorySection from './MonthlyHistorySection';

const UISTATE = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

const PAGINATION = {
  limit: 10,
  current: 1,
};

export default function MonthlyDetailPage(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const [uiState, setUiState] = useState(UISTATE.LIST);
  const [actions] = useOutlet('actions');
  const [extraLoading, setExtraLoading] = useState(false);
  const [loading, setLoading] = useOutlet('loading');
  const [record, setRecord] = useState();
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [buyer, setBuyer] = useState();
  const [entData, setEntData] = useState();
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openPdfDialog, setOpenPdfDialog] = useState(false);

  function filtersChange(obj) {
    setFilters((prev) => ({...prev, ...obj}));
  }

  const getRecord = useCallback(async (id) => {
    try {
      let resp = await actions.getMonthlyOrder(id);
      setRecord(resp);
      return resp;
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const getOrders = useCallback(
    async (id) => {
      try {
        let resp = await actions.getOrders({
          payment_type: 'monthly',
          limit: filters.limit,
          offset: (filters.current - 1) * filters.limit,
          ordering: '-created',
          monthly_order: id,
        });
        setOrders(resp.results);
        setTotal(resp.count);
      } catch (err) {
        errorHandler(err);
      }
    },
    [filters],
  );

  const getInvoices = useCallback(async (id) => {
    if (id) {
      try {
        let resp = await actions.getInvoices({
          monthly_order: id,
          ordering: '-created',
        });
        setInvoices(resp.results);
      } catch (err) {
        console.warn(err);
      }
    }
  }, []);

  const getBuyer = useCallback(async (buyer_id) => {
    if (buyer_id) {
      try {
        let resp = await actions.getMember(buyer_id);
        setBuyer(resp);

        setEntData(JSON.parse(resp.data));
      } catch (err) {
        console.warn(err);
      }
    }
  }, []);

  const notif = useCallback(async (id) => {
    actions.setLoading(true);
    if (id && window.confirm('將會再次寄送繳費過期通知信，確認繼續嗎？')) {
      try {
        await actions.notifyExpiredMonthly({
          monthly_order: id,
        });
        Ant.message.success('已寄出通知');
      } catch (err) {
        errorHandler(err);
      }
    }
    actions.setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setExtraLoading(true);
      await Promise.all([getOrders(id), getInvoices(id)]);
      setExtraLoading(false);
    })();
  }, [id, getOrders]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let monthlyOrder = await getRecord(id);
      await getBuyer(monthlyOrder.buyer);
      setLoading(false);
    })();
  }, [id, getRecord, getBuyer]);

  if (!record) {
    return (
      <Wrapper>
        <BackButton />
        <h2>找不到月結紀錄</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button type="primary" onClick={() => setOpenDialog(DIALOG_TYPE.EDIT)}>
          編輯
        </Button>
        <Button
          type="primary"
          style={{marginLeft: 8}}
          onClick={() => setOpenPdfDialog(true)}>
          下載月結單
        </Button>
        <Button
          type="primary"
          disabled={record?.payment_status !== 'expired'}
          style={{marginLeft: 8}}
          onClick={() => notif(record?.id)}>
          催繳通知
        </Button>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={async () => {
            setLoading(true);
            await Promise.all([
              getRecord(id),
              getOrders(id),
              getInvoices(id),
              getBuyer(id),
            ]);
            setLoading(false);
          }}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>
      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          <Block>
            <h2>月結單資訊 # {record.id}</h2>
            <div className="divider" />
            <div>
              <div className="row">
                <h4>繳費狀態</h4>
                <div>#{record.id}</div>
              </div>
              <div className="row">
                <h4>繳費狀態</h4>
                <Tag color={MONTHLY_ORDER_STATUS_COLOR[record.payment_status]}>
                  {MONTHLY_ORDER_STATUS[record.payment_status]}
                </Tag>
              </div>
              <div className="row">
                <h4>月結區間</h4>
                <div>
                  {record.period_from} ～ {record.period_to}
                </div>
              </div>
              <div className="row">
                <h4>當期消費總金額</h4>
                <div>{record.amount}</div>
              </div>
              <div className="row">
                <h4>當月繳費截止日</h4>
                <div>{dateParser(record.pay_deadline, 'YYYY-MM-DD HH:mm')}</div>
              </div>

              <div className="row">
                <h4>後台人員備註</h4>
                <div>{record.note}</div>
              </div>
              <div className="row">
                <h4>匯款戶名</h4>
                <div>{record.remittance_account || '-'}</div>
              </div>
              <div className="row">
                <h4>匯款後五碼</h4>
                <div>{record.remittance_number || '-'}</div>
              </div>
              <div className="row">
                <h4>匯款日期</h4>
                <div>{record.remitted_time || '-'}</div>
              </div>
            </div>
          </Block>
        </Ant.Col>
        <Ant.Col span={6}>
          <InvoiceBlock
            monthlyOrder={record}
            records={invoices}
            buyer={buyer}
            onUpdate={() => getInvoices(id)}
          />
        </Ant.Col>
        <Ant.Col span={6}>
          {buyer && entData && (
            <MemberEnterpriseBlock record={buyer} entData={entData} />
          )}
        </Ant.Col>
        <Ant.Col span={24}>
          <Block>
            <h2>當期月結訂單</h2>
            <div className="divider" />
            <OrderTable
              type={TableTypes.MONTHLY}
              loading={extraLoading}
              orders={orders}
              mounted={false}
              onUpdate={getOrders}
              pagination={{
                pageSize: filters.limit,
                current: filters.current,
                total,
              }}
              onPaging={(pagination) =>
                filtersChange({
                  limit: pagination.pageSize,
                  current: pagination.current,
                })
              }
            />
          </Block>
        </Ant.Col>
        <Ant.Col span={12}>
          <MonthlyHistorySection monthlyOrder={record} />
        </Ant.Col>
      </Ant.Row>

      <Dialog
        type={openDialog}
        record={record}
        onClose={() => setOpenDialog(false)}
        onUpdate={() => getRecord(id)}
      />

      <PdfPreviewDialog
        visible={!!openPdfDialog}
        loading={!record || !buyer}
        onClose={() => setOpenPdfDialog(null)}
        PDFDownloadComp={
          <MonthlyFormPdfDownload
            monthlyOrder={record}
            orders={orders}
            profile={buyer}
            invoice={invoices.slice(0, 1).pop()}
          />
        }>
        <MonthlyDocument
          monthlyOrder={record}
          orders={orders}
          profile={buyer}
          invoice={invoices.slice(0, 1).pop()}
        />
      </PdfPreviewDialog>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
