import React, {useCallback, useEffect, useState} from 'react';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {dateParser} from '../../Utils/DateUtil';
import HistoryUtil from '../../Utils/HistoryUtil';
import {HISTORY_MODEL} from '../../dictionary';
import Button from '../../Widgets/Button';
import Block from '../../Widgets/Block';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {errorHandler} from '../../errors';
const appConfig = require('../../data.json');

const PAGINATION = {
  limit: 10,
  current: 1,
};

export default function MonthlyHistorySection({monthlyOrder}) {
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [actions] = useOutlet('actions');
  const [jwtToken] = useOutlet('jwtToken');
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });

  const getRecords = useCallback(async () => {
    setLoading(true);
    if (monthlyOrder && jwtToken) {
      try {
        let resp = await actions.getHistories({
          query: {
            $or: [
              {instance_id: monthlyOrder.id, model: 'MonthlyOrder'},
              {monthly_order_id: monthlyOrder.id, model: 'Invoice'},
            ],
          },
          paging: {
            limit: 100, // filters.limit,
            offset: 0, // (filters.current - 1) * filters.limit
          },
          sorting: ['-created'],
        });

        let {results, total} = resp;

        setRecords(results);
        setTotal(total);
      } catch (err) {
        errorHandler(err);
      }
    }

    setLoading(false);
  }, [actions, monthlyOrder, filters, jwtToken]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Block>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h2>歷史紀錄</h2>

        <Button
          shape="circle"
          onClick={getRecords}
          style={{border: 0}}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </div>
      <div className="divider" />

      <Ant.Timeline>
        {records.map((record, idx) => (
          <Ant.Timeline.Item key={idx}>
            <p>
              {HISTORY_MODEL[record.model]} / {dateParser(record.created)} /{' '}
              {record.user.name || record.user.username}
            </p>
            <p>
              {HistoryUtil.parse(record.model, record.value) ||
                JSON.stringify(record.value)}
            </p>
          </Ant.Timeline.Item>
        ))}
      </Ant.Timeline>
    </Block>
  );
}
