import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import {getQuantity, parseProductConfig} from './PdfCartItemTable';
import appConfig from '../data.json';
import {
  MONTHLY_ORDER_STATUS,
  MONTHLY_TYPE,
  ORDER_STATE,
  ORDER_TYPE,
} from '../dictionary';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  image: {
    width: 200,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '600',
    paddingVertical: 16,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: '600',
    paddingBottom: 18,
  },
  text: {
    flex: 1,
    fontWeight: '500',
    fontSize: 10,
    textAlign: 'center',
  },
  textBig: {
    fontSize: 14,
  },
  form: {
    marginBottom: 10,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#b9b9b9',
  },
  firstRow: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3f3f3',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  firstRowCell: {
    textAlign: 'center',
    fontSize: 10,
    flex: 1,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  firstRowCellBig: {
    textAlign: 'center',
    fontSize: 10,
    flex: 2,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cell: {
    fontSize: 10,
    flex: 1,
    paddingHorizontal: 3,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellBig: {
    flexDirection: 'column',
    fontSize: 10,
    flex: 2,
    paddingHorizontal: 3,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellBigger: {
    fontSize: 10,
    flex: 4,
    paddingHorizontal: 3,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellMini: {
    fontSize: 10,
    width: 100,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  higher: {
    paddingVertical: 12,
  },
  label: {
    fontSize: 10,
    flex: 1,
    paddingHorizontal: 3,
    backgroundColor: '#f2f2f2',
  },
});

// Create Document Component
const MonthlyDocument = (props) => {
  let {monthlyOrder, orders, profile, invoice} = props;
  const entData = JSON.parse(profile.data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{appConfig.information.name}</Text>
        <Text style={styles.subtitle}>月結應收帳款對帳單（月結請款單）</Text>
        <View
          style={{marginBottom: 10, flexDirection: 'column', borderBottom: 0}}>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司抬頭</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {profile.ent_name}
            </Text>
            <Text style={styles.label}>訂單日期：</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {monthlyOrder.created.split('T')[0]}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={[styles.text, {backgroundColor: '#fef1cb'}]}>
              會員資訊
            </Text>
            <Text style={[styles.text, {backgroundColor: '#fce3d5'}]}>
              月結單資訊
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>會員編號</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>{profile.id}</Text>
            <Text style={styles.label}>月結結帳日</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              指定{MONTHLY_TYPE[profile?.monthly_type] || '-'}
            </Text>
          </View>

          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>顧客姓名</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {profile.name}
            </Text>
            <Text style={styles.label}>對帳日期</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {monthlyOrder.period_from} ~ {monthlyOrder.period_to}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司抬頭</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {profile.ent_name}
            </Text>
            <Text style={styles.label}>繳費緩衝期</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {profile.monthly_pay_buffer}天
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司統編</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {profile.gui_number}
            </Text>
            <Text style={styles.label}>繳費截止日</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {monthlyOrder.pay_deadline.slice(0, 16).replace('T', ' ')}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司電話</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {entData.ent_tel}
            </Text>
            <Text style={styles.label}>繳費狀態</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {MONTHLY_ORDER_STATUS[monthlyOrder.payment_status]}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司分機</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {entData.ent_tel_ext}
            </Text>
            <Text style={styles.label}>當期消費總金額</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {monthlyOrder.amount}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.label}>公司地址</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {entData.ent_zip_code} {entData.ent_city} {entData.ent_distinct}{' '}
              {entData.ent_address}
            </Text>
            <Text style={styles.label}>月結訂單數</Text>
            <Text style={[styles.cellBig, {borderRight: 0}]}>
              {orders.length}筆
            </Text>
          </View>
        </View>

        <View style={[styles.form, {borderBottom: 0}]}>
          <View style={{...styles.firstRow, backgroundColor: '#d8ead3'}}>
            <Text style={[styles.text, styles.higher]}>月結訂單明細</Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {textAlign: 'center'}]}>建立時間</Text>
            <Text style={[styles.cellBig, {textAlign: 'center'}]}>
              訂單編號
            </Text>
            <Text style={[styles.cell, {textAlign: 'center'}]}>訂單類型</Text>
            <Text
              style={[
                styles.cellBig,
                {borderRightWidth: 0, textAlign: 'center'},
              ]}>
              金額
            </Text>
          </View>

          {orders.map((order, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={[styles.cell, {textAlign: 'center'}]}>
                {order.created.slice(0, 10)}
              </Text>
              <Text style={[styles.cellBig, {textAlign: 'center'}]}>
                {order.display_id}
              </Text>
              <Text style={[styles.cell, {textAlign: 'center'}]}>
                {ORDER_TYPE[order.order_type]}
              </Text>
              <Text
                style={[
                  styles.cellBig,
                  {borderRightWidth: 0, textAlign: 'center'},
                ]}>
                {order.amount}
              </Text>
            </View>
          ))}

          <View style={styles.row}>
            <Text
              style={[styles.cellBigger, styles.higher, {textAlign: 'right'}]}>
              本期應收總總和金額
            </Text>
            <Text
              style={[
                styles.cellBig,
                styles.higher,
                {
                  flex: 0,
                  flexBasis: 168,
                  borderRightWidth: 0,
                  textAlign: 'center',
                },
              ]}>
              {monthlyOrder.amount}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={[styles.cell, {textAlign: 'center'}]}>發票日期</Text>
            <Text style={[styles.cellBig, {textAlign: 'center'}]}>
              {invoice ? invoice.created.slice(0, 16).replace('T', ' ') : null}
            </Text>
            <Text style={[styles.cell, {textAlign: 'center'}]}>
              當期繳費截止日
            </Text>
            <Text
              style={[
                styles.cellBig,
                {borderRightWidth: 0, textAlign: 'center'},
              ]}>
              {monthlyOrder.pay_deadline.slice(0, 16).replace('T', ' ')}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={[styles.cell, {textAlign: 'center'}]}>發票號碼</Text>
            <Text style={[styles.cellBig, {textAlign: 'center'}]}>
              {invoice?.invoice_number}
            </Text>
            <Text style={[styles.cell, {textAlign: 'center'}]}></Text>
            <Text
              style={[
                styles.cellBig,
                {borderRightWidth: 0, textAlign: 'center'},
              ]}></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
const MonthlyFormPdfDownload = (props) => {
  return (
    <div>
      <PDFDownloadLink
        style={props.style}
        document={<MonthlyDocument {...props} />}
        fileName="月結對帳單.pdf">
        {({blob, url, loading, error}) =>
          loading ? '下載中...' : `匯出月結對帳單`
        }
      </PDFDownloadLink>
    </div>
  );
};

export {MonthlyFormPdfDownload, MonthlyDocument};
